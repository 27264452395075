<template>
  <doctor-profile-component />
</template>

<script>
import DoctorProfileComponent from "@/components/medical-services/DoctorProfileComponent";

export default {
  name: "DoctorProfile",
  components: { DoctorProfileComponent },
  title: "Doctor Profile | Baja California Health Tourism",
};
</script>

<style scoped></style>
